import { ConfigActionTypes, Currency, ISetCurrency } from "./types";

function makeAction<T, P>(type: T) {
  return function (payload: P) {
    return {
      type,
      payload,
    };
  };
}

export enum Types {
  TEST = "TEST",
}

export const setTest = makeAction<Types.TEST, string>(Types.TEST);

export const setVideos = makeAction<ConfigActionTypes.SET_VIDEOS, number>(
  ConfigActionTypes.SET_VIDEOS
);

export const setDuration = (duration: number) => {
  return {
    type: ConfigActionTypes.SET_DURATION,
    payload: duration,
  };
};

export const toggleElearning = () => {
  return {
    type: ConfigActionTypes.SET_ELEARNING,
  };
};

export const setNarrator = (id: number) => ({
  type: ConfigActionTypes.SET_NARRATOR,
  payload: id,
});

export const setNewStyle = (id: boolean) => ({
  type: ConfigActionTypes.SET_NEW_STYLE,
  payload: id,
});

export const setCopy = (val: boolean) => ({
  type: ConfigActionTypes.SET_COPY,
  payload: val,
});

export const setLanguage = (langId: number) => ({
  type: ConfigActionTypes.SET_LANGUAGE,
  payload: langId,
});

export const setAdditionalLanguages = (languages: number) => ({
  type: ConfigActionTypes.SET_ADDITIONAL_LANGUAGE,
  payload: languages,
});

export const setAdditionalTranslation = (val: boolean) => ({
  type: ConfigActionTypes.SET_ADDITIONAL_TRANSLATION,
  payload: val,
});

export const setCutdown = (val: boolean) => ({
  type: ConfigActionTypes.SET_CUTDOWN,
  payload: val,
});

export const setExportIllustrations = (val: boolean) => ({
  type: ConfigActionTypes.SET_EXPORT_ILLUSTRATIONS,
  payload: val,
});

export const setSpecialOffer = (val: boolean) => ({
  type: ConfigActionTypes.SET_SPECIAL_OFFER,
  payload: val,
});

export const setAdditionalDub = (val: boolean) => ({
  type: ConfigActionTypes.SET_ADDITIONAL_DUB,
  payload: val,
});

export const setAdLicence = (val: boolean) => ({
  type: ConfigActionTypes.SET_AD_LICENCE,
  payload: val,
});

export const setCurrency = (val: typeof Currency.SEK): ISetCurrency => ({
  type: ConfigActionTypes.SET_CURRENCY,
  payload: val,
});
