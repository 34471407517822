import React from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import { ReactComponent as Fail } from "../../assets/fail.svg";
import { ReactComponent as Success } from "../../assets/success.svg";
import { ReactComponent as Processing } from "../../assets/processing.svg";
import { useSelector } from "react-redux";
import { IAppState } from "../../store";
import { MessageState } from "../../store/message/reducer";

import "./Message.css";

let container = document.querySelector("#root-2");

export const Message = () => {
  const state = useSelector<IAppState, MessageState>((state) => state.message);
  if (!container) return null;
  return ReactDOM.createPortal(
    <div
      className={classnames("message", { "message--hidden": !state.display })}
    >
      <div className="message_icon">
        <div>
          {state.type === "danger" && <Fail />}
          {state.type === "success" && <Success />}
          {state.type === "info" && <Processing />}
        </div>
      </div>
      <div className="message_text">{state.text}</div>
    </div>,
    container
  );
};
