import React from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useCosts, useRelativePrices } from "../../lib/calc";
import { IAppState } from "../../store";
import { ConfigurationState } from "../../store/configuration/reducer";
import { submit } from "../../store/form/actions";
import { FormState } from "../../store/form/reducer";
import { PriceState } from "../../store/prices/reducer";

import "./FooterButton.css";
import Localize from "../localize";

export const FooterButton = () => {
  const { text, classNames, click } = useNext();
  const form = useSelector<IAppState, FormState>((state) => state.form);
  return (
    <div className={classNames} onClick={click}>
      <Localize name={text} />
      {form.submitting && (
        <div className="footer-button_spinner">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
};

function useNext() {
  const history = useHistory();
  const { step } = useParams<{ step: string }>();
  const submit = useSubmit();
  const form = useSelector<IAppState, FormState>((state) => state.form);
  const summary = step === "summary";
  const click = () => {
    console.log(step);
    if (summary) {
      submit();
      return;
    }
    history.push(GetNextRoute(step));
  };
  const text = summary ? (form.submitted ? "submitted" : "submit") : "next";
  const classNames = classnames("footer-button", {
    "footer-button--submitting": form.submitting,
    "footer-button--submitted": form.submitted,
  });
  return { text, classNames, click };
}

function useSubmit() {
  const dispatch = useDispatch();
  const config = useSelector<IAppState, ConfigurationState>(
    (state) => state.config
  );
  const price = useSelector<IAppState, PriceState>((state) => state.prices);
  const relative = useRelativePrices(config.currency);
  const costs = useCosts(config.currency);
  const { lang } = useParams<{ lang: string }>();
  return () => dispatch(submit(price, relative, costs, config, lang));
}

function GetNextRoute(currentPath: string): string {
  switch (currentPath) {
    case "start":
      return "involvement";
    case "involvement":
      return "language";
    case "language":
      return "addons";
    case "addons":
      return "summary";
    default:
      return "start";
  }
}
