import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberToCurrency } from "../../lib/format";

import { IAppState } from "../../store";
import { ConfigurationState as ConfigState } from "../../store/configuration/reducer";

import Button from "./button";
import { useRelativePrices } from "../../lib/calc";
import {
  setAdLicence,
  setCutdown,
  setExportIllustrations,
  setSpecialOffer,
} from "../../store/configuration/actions";
import Localize from "../localize";
import { useLocalize } from "../../lib/localise";

export default function DrawerAddons() {
  const localize = useLocalize();
  const config = useSelector<IAppState, ConfigState>((store) => store.config);
  const relative = useRelativePrices();
  const dispatch = useDispatch();
  return (
    <div>
      <h2>
        <Localize name="addons_ad_title" />
      </h2>
      <p>
        <Localize name="addons_ad_text" />
      </p>

      <Button
        text={localize("ad")}
        active={config.narrator !== 0 && config.ad_licence}
        disabled={config.narrator === 0}
        onClick={() => dispatch(setAdLicence(!config.ad_licence))}
      >
        {config.narrator === 0 ? (
          <div>N/A</div>
        ) : (
          <div>
            {numberToCurrency(relative.ad_licence, true, config.currency.text)}
          </div>
        )}
      </Button>

      <h2>
        <Localize name="addons_cutdown_title" />
      </h2>
      <p>
        <Localize name="addons_cutdown_text" />
      </p>
      <Button
        text={localize("cutdown")}
        active={config.cutdown}
        onClick={() => dispatch(setCutdown(!config.cutdown))}
      >
        <div>
          {numberToCurrency(relative.cutdown, true, config.currency.text)}
        </div>
      </Button>

      <h2>
        <Localize name="addons_illustrations_title" />
      </h2>
      <p>
        <Localize name="addons_illustrations_text" />
      </p>
      <Button
        text={localize("export_illustrations")}
        active={config.export_illustrations}
        onClick={() =>
          dispatch(setExportIllustrations(!config.export_illustrations))
        }
      >
        <div>
          {numberToCurrency(
            relative.export_illustrations,
            true,
            config.currency.text
          )}
        </div>
      </Button>

      <h2>
        <Localize name="addons_premium_title" />
      </h2>
      <p>
        <Localize name="addons_premium_text" />
      </p>
      <Button
        text={localize("premium_trial")}
        active={!config.special_offer}
        onClick={() => dispatch(setSpecialOffer(false))}
      >
        <div>{localize("included")}</div>
      </Button>
      <Button
        text={localize("premium_special")}
        active={config.special_offer}
        onClick={() => dispatch(setSpecialOffer(true))}
      >
        <div>
          {numberToCurrency(relative.special_offer, true, config.currency.text)}
        </div>
      </Button>
    </div>
  );
}
