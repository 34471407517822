import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import "./Drawer.css";
import DrawerAddons from "./DrawerAddons";
import DrawerInvolvement from "./DrawerInvolvement";
import DrawerLanguage from "./DrawerLanguage";

import StartingPoint from "./DrawerStart";
import Summary from "./Summary";
import Discount from './Discount';

function DrawerStep({ step }: { step: string }) {
  switch (step) {
    case "start":
      return <StartingPoint />;
    case "involvement":
      return <DrawerInvolvement />;
    case "language":
      return <DrawerLanguage />;
    case "addons":
      return <DrawerAddons />;
    case "summary":
      return <Summary />;
    default:
      return <></>;
  }
}

export function Drawer() {
  const { step } = useParams<{ step: string }>();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) ref.current.scrollTop = 0;
  }, [step]);
  return (
    <div className="drawer" ref={ref}>
      <div className="drawer-step">
        <DrawerStep step={step} />
      </div>
      {/*<Discount/>*/}
    </div>
  );
}
