import React from "react";
import Price from "../price";
import "./Footer.css";
import { FooterButton } from "./FooterButton";

export function Footer() {
  return (
    <div className="footer">
      <div className="footer_section">
        <Price />
        <div className="footer_section">
          <FooterButton />
          <div className="footer_divider"></div>
        </div>
      </div>

      <div className="footer_section"></div>
    </div>
  );
}
