import { IAppState } from "..";

export enum MessageTypes {
  SET_TEXT = "SET_TEXT",
  SET_DISPLAY = "SET_DISPLAY",
  SET_MESSAGE = "SET_MESSAGE",
  ADD_QUEUE = "ADD_QUEUE",
  REMOVE_QUEUE = "REMOVE_QUEUE",
}

const DELAY = 100;

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const setMessage = (text: string, type: string) => async (
  dispatch: any,
  getState: () => IAppState
) => {
  const state = getState();

  if (state.message.queue.length === 0 && !state.message.display) {
    dispatch(setText(text, type));
  } else {
    dispatch(addToQueue(text, type));
    const now = Date.now();
    if (state.message.queue.length === 0) {
      if (now - state.message.latest < DELAY) {
        await wait(DELAY - (now - state.message.latest));
      }
      dispatch(nextMessage());
    }
  }
};

const nextMessage = () => async (dispatch: any, getState: () => IAppState) => {
  const state = getState();
  if (state.message.queue.length !== 0) {
    dispatch(setDisplay(false));
    await wait(DELAY);
    // @ts-ignore
    const text = state.message.queue[0].text;
    // @ts-ignore
    const type = state.message.queue[0].type;
    dispatch(setText(text, type));
    dispatch(removeFromQueue());
    await wait(DELAY);
    dispatch(nextMessage());
  }
};

const addToQueue = (text: string, type: string) => {
  return {
    type: MessageTypes.ADD_QUEUE,
    payload: { text, type },
  };
};

const removeFromQueue = () => {
  return {
    type: MessageTypes.REMOVE_QUEUE,
  };
};

export const setDisplay = (val: boolean) => {
  return {
    type: MessageTypes.SET_DISPLAY,
    payload: val,
  };
};

export const setText = (text: string, type: string) => {
  return {
    type: MessageTypes.SET_MESSAGE,
    payload: { text, type },
  };
};
