import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store";
import {
  setEmail,
  setMsg,
  setName,
  setOrg,
  setPhone,
} from "../../store/form/actions";
import { FormState } from "../../store/form/reducer";
import Localize from "../localize";

function SubmitForm() {
  const form = useSelector<IAppState, FormState>((state) => state.form);
  const d = useDispatch();
  return (
    <div className="submit-form">
      <h2>
        <Localize name="form_details_title" />
      </h2>
      <p>
        <Localize name="form_details_text" />
      </p>
      <form>
        <div className="main_input-controller">
          <label htmlFor="name" className="main_input-label">
            <Localize name="form_name" />
          </label>
          <input
            id="name"
            className="main_input"
            type="name"
            value={form.name}
            onChange={(e) => d(setName(e.target.value))}
          ></input>
        </div>
        <div className="main_input-controller">
          <label htmlFor="org" className="main_input-label">
            <Localize name="form_org" />
          </label>
          <input
            id="org"
            className="main_input"
            type="org"
            value={form.org}
            onChange={(e) => d(setOrg(e.target.value))}
          ></input>
        </div>
        <div className="main_input-controller">
          <label htmlFor="email" className="main_input-label">
            <Localize name="form_email" />
            <span className="main_input-label-accent">*</span>
          </label>
          <input
            id="email"
            className="main_input"
            type="email"
            value={form.email}
            onChange={(e) => d(setEmail(e.target.value))}
          ></input>
        </div>
        <div className="main_input-controller">
          <label htmlFor="phone" className="main_input-label">
            <Localize name="form_phone" />
          </label>
          <input
            id="phone"
            className="main_input"
            type="phone"
            value={form.phone}
            onChange={(e) => d(setPhone(e.target.value))}
          ></input>
        </div>
        <div className="main_input-controller-area">
          <label htmlFor="details" className="main_input-label">
            <Localize name="form_extra" />
          </label>
          <textarea
            id="details"
            className="main_input main_input-area"
            value={form.msg}
            onChange={(e) => d(setMsg(e.target.value))}
          ></textarea>
        </div>
      </form>
    </div>
  );
}

export default SubmitForm;
