import React from "react";
import { useParams } from "react-router-dom";
import "./Main.css";
import SubmitForm from "./SubmitForm";
import Localize from '../localize';

const videoEmbedLinks = {
  start: {
    sv: "https://player.vimeo.com/video/470305202",
    en: "https://player.vimeo.com/video/470305235",
  },
  involvement: {
    sv: "https://player.vimeo.com/video/470305328",
    en: "https://player.vimeo.com/video/470305364",
  },
  language: {
    sv: "https://player.vimeo.com/video/470305512",
    en: "https://player.vimeo.com/video/470305465",
  },
  addons: {
    sv: "https://player.vimeo.com/video/470305581",
    en: "https://player.vimeo.com/video/470305546",
  },
};

type Languages = "sv" | "en";
type Steps = "start" | "involvement" | "language" | "addons" | "summary";
type Params = { step: Steps; lang: Languages };

function getVimeoEmbedLink(step: Steps, lang: Languages): string {
  if (step === "summary") return "";
  return videoEmbedLinks[step][lang];
}

export default function Main() {
  const { step, lang } = useParams<Params>();
  return (
    <div className="main">
      {step === "summary" && <SubmitForm />}
      {step !== "summary" && (
        <div className="player">
          <div className="player_sizer">
            <iframe
              title="vimeo-video-player"
              className="vimeo-frame"
              src={getVimeoEmbedLink(step, lang)}
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
      {/*<div className="discount-banner"><Localize name="discount-info" /> <span>&#127881;</span></div>*/}
    </div>
  );
}
