export function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function numberToCurrency(x: number, sign = false, suffix = "SEK") {
  const signStr = sign ? (x < 0 ? "- " : "+ ") : "";
  let num = numberWithSpaces(Math.abs(x));
  if (suffix !== "SEK") num = num.replace(" ", ",");
  return signStr + num + " " + suffix;
}
