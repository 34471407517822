import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { configurationReducer } from "./configuration/reducer";
import { FormReducer } from "./form/reducer";
import { pricesReducer } from "./prices/reducer";
import thunk from "redux-thunk";
import { MessageReducer } from "./message/reducer";

const reducers = combineReducers({
  config: configurationReducer,
  prices: pricesReducer,
  form: FormReducer,
  message: MessageReducer,
});

export type IAppState = ReturnType<typeof reducers>;

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);
