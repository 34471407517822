const initial = {
  new_library: 15000,
  // base price of a scene/building block.
  new_scene: 2000,
  // base price
  base: 15,
  // export cost
  export: 4000,
  // if adme handles the production
  adme_multiplier: 0.7,
  // Proffesional (2000) / Simple (0)
  breif: 2000,
  // Copywriter (4000) / No (0)
  copywriter: 4000,
  elearning: 6000,
  cutdown: 4000,
  export_illustrations: 4000,
  ad_licence: 3000,
  // Clients who already have a style
  returning_multiplier: 0.85,
  additional_language_narration: 8000,
  additional_language_sub: 1500,
  additional_language_translation: 1500,
  special_offer: 12000,
  additional_languages: [
    { id: 0, describe: "0 Additional Languages", multiplier: 1 },
    { id: 0, describe: "1 Additional Languages", multiplier: 1 },
    { id: 0, describe: "2 Additional Languages", multiplier: 0.9 },
    { id: 0, describe: "3 Additional Languages", multiplier: 0.85 },
    { id: 0, describe: "4 Additional Languages", multiplier: 0.8 },
    { id: 0, describe: "5 Additional Languages", multiplier: 0.75 },
    { id: 0, describe: "6 Additional Languages", multiplier: 0.7 },
    { id: 0, describe: "7 Additional Languages", multiplier: 0.65 },
    { id: 0, describe: "8 Additional Languages", multiplier: 0.6 },
    { id: 0, describe: "9 Additional Languages", multiplier: 0.55 },
    { id: 0, describe: "10 Additional Languages", multiplier: 0.5 },
  ],
  naration: [
    { id: 0, describe: "None", price: 0 },
    { id: 1, describe: "Basic", price: 3000 },
    { id: 2, describe: "Standard", price: 5000, primary: true },
    { id: 3, describe: "Premium", price: 10000 },
  ],
  languages: [
    { id: 0, describe: "swedish", price: 0 },
    { id: 1, describe: "english", price: 0 },
  ],
  // Multipliers for duration of video
  duration_multipliers: [
    { describe: "30 Seconds", multiplier: 0.666666666667 },
    { describe: "1 Minute", multiplier: 1.0 },
    { describe: "2 Minutes", multiplier: 1.6 },
    { describe: "3 Minutes", multiplier: 2.2 },
    { describe: "4 Minutes", multiplier: 2.8 },
    { describe: "5 Minutes", multiplier: 3.33333333333333 },
  ],
  // Multipliers for amount of videos
  volume_multipliers: [
    { describe: "1 Video", multiplier: 1.0 },
    { describe: "2 Videos", multiplier: 0.9 },
    { describe: "3 Videos", multiplier: 0.85 },
    { describe: "4 Videos", multiplier: 0.8 },
    { describe: "5 Videos", multiplier: 0.75 },
    { describe: "6 Videos", multiplier: 0.7 },
    { describe: "7 Videos", multiplier: 0.65 },
    { describe: "8 Videos", multiplier: 0.6 },
    { describe: "9 Videos", multiplier: 0.55 },
    { describe: "10 Videos", multiplier: 0.5 },
  ],
};

export type PriceState = typeof initial;

export const pricesReducer = (state = initial, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};
