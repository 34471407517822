import React from "react";
import classnames from "classnames";

import { ReactComponent as Plus } from "../../../assets/plus.svg";
import { ReactComponent as Minus } from "../../../assets/minus.svg";

import "./NumberButtonModule.css";
function NumberButtonModule({
  inc = () => {},
  dec = () => {},
  max = false,
  min = false,
}) {
  return (
    <div
      className={classnames("number-button-module", {
        "number-button-module--min": min,
        "number-button-module--max": max,
      })}
    >
      <div
        onClick={() => {
          if (!min) dec();
        }}
      >
        <Minus />
      </div>
      <div
        onClick={() => {
          if (!max) inc();
        }}
      >
        <Plus />
      </div>
    </div>
  );
}

export default NumberButtonModule;
