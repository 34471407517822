import React from "react";
import { useSelector } from "react-redux";
import { useCosts, useRelativePrices } from "../../lib/calc";
import { numberToCurrency } from "../../lib/format";
import { useLocalize } from "../../lib/localise";
import { IAppState } from "../../store";
import { ConfigurationState } from "../../store/configuration/reducer";

export default function Summary() {
  const localize = useLocalize();
  const relative = useRelativePrices();
  const costs = useCosts();
  const config = useSelector<IAppState, ConfigurationState>(
    (store) => store.config
  );

  return (
    <div className="summary">
      <h2>Summary</h2>
      <h3>{localize("start")}</h3>
      <div>
        <div className="summary_item">
          <div>
            {localize("video_" + (config.duration - 1))
             + config.videos}
          </div>
          <div>
            {numberToCurrency(
              costs.production +
                relative.brief +
                relative.library +
                relative.narrations[2].price +
                relative.copwriter +
                relative.videos,
              false,
              config.currency.text
            )}
          </div>
        </div>
        {!!config.elearning && (
          <div className="summary_item">
            <div>{localize("e-learning")}</div>
            <div>
              {numberToCurrency(relative.elearning, true, config.currency.text)}
            </div>
          </div>
        )}
      </div>

      <h3>{localize("involvement")}</h3>
      <div>
        <div className="summary_item">
          <div>{localize("new_style")} </div>
          <div>
            {config.new_style
              ? localize("included")
              : numberToCurrency(-relative.library, true, config.currency.text)}
          </div>
        </div>
        <div className="summary_item">
          <div>{localize("copywriter")}</div>
          <div>
            {config.copywriter
              ? localize("included")
              : numberToCurrency(
                  -relative.copwriter,
                  true,
                  config.currency.text
                )}
          </div>
        </div>
        <div className="summary_item">
          <div>Voiceover: {localize("voiceover_" + config.narrator)}</div>
          <div>
            {config.narrator === 2
              ? localize("included")
              : numberToCurrency(
                  relative.narrations[config.narrator].price -
                    relative.narrations[2].price,
                  true,
                  config.currency.text
                )}
          </div>
        </div>
      </div>

      <h3>{localize("language")}</h3>
      <div>
        <div className="summary_item">
          <div>
            {config.language ? localize("english") : localize("swedish")}{" "}
          </div>
          <div>{localize("included")}</div>
        </div>
        {!!config.additional_languages && config.additional_dub && (
          <div className="summary_item">
            <div>{localize("dubbed")}</div>
            <div>
              {numberToCurrency(
                relative.additional_language_narrator +
                  relative.additional_language_translation,
                true,
                config.currency.text
              )}
            </div>
          </div>
        )}
        {!!config.additional_languages && !config.additional_dub && (
          <div className="summary_item">
            <div>{localize("subtitled")}</div>
            <div>
              {numberToCurrency(
                relative.additional_language_sub +
                  relative.additional_language_translation,
                true,
                config.currency.text
              )}
            </div>
          </div>
        )}
        {!!config.additional_languages && !config.additional_translation && (
          <div className="summary_item">
            <div>{localize("translate_them")}</div>
            <div>
              {numberToCurrency(
                -relative.additional_language_translation,
                true,
                config.currency.text
              )}
            </div>
          </div>
        )}
      </div>

      <h3>{localize("addons")}</h3>
      <div>
        {!!config.cutdown && (
          <div className="summary_item">
            <div>{localize("cutdown")}</div>
            <div>
              {numberToCurrency(relative.cutdown, true, config.currency.text)}
            </div>
          </div>
        )}
        {!!config.export_illustrations && (
          <div className="summary_item">
            <div>{localize("export_illustrations")}</div>
            <div>
              {numberToCurrency(
                relative.export_illustrations,
                true,
                config.currency.text
              )}
            </div>
          </div>
        )}
        {!!config.special_offer ? (
          <div className="summary_item">
            <div>{localize("premium_special")}</div>
            <div>
              {numberToCurrency(
                relative.special_offer,
                true,
                config.currency.text
              )}
            </div>
          </div>
        ) : (
          <div className="summary_item">
            <div>{localize("premium_trial")}</div>
            <div>{localize("included")}</div>
          </div>
        )}
      </div>
    </div>
  );
}
