import React from "react";
import classnames from "classnames";
import "./Button.css";

interface Props {
  text: string;
  active: boolean;
  disabled?: boolean;
  onClick?(): void;
  children?: any;
}

export function Button({
  text = "",
  active = false,
  disabled = false,
  onClick = () => {},
  children = null,
}: Props) {
  return (
    <div
      className={classnames("button", {
        "button--active": active,
        "button--can-click": onClick,
        "button--disabled": disabled,
      })}
      onClick={onClick}
    >
      {text}
      {children}
    </div>
  );
}
