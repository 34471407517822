import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberToCurrency } from "../../lib/format";

import { IAppState } from "../../store";
import { PriceState } from "../../store/prices/reducer";
import { ConfigurationState as ConfigState } from "../../store/configuration/reducer";

import Button from "./button";
import {
  setAdditionalLanguages,
  setAdditionalDub,
  setAdditionalTranslation,
  setLanguage,
} from "../../store/configuration/actions";
import NumberButtonModule from "./button/NumberButtonModule";
import { useRelativePrices } from "../../lib/calc";
import Localize from "../localize";
import { useLocalize } from "../../lib/localise";

export default function DrawerLanguage() {
  const localize = useLocalize();
  const config = useSelector<IAppState, ConfigState>((store) => store.config);
  const prices = useSelector<IAppState, PriceState>((store) => store.prices);
  const relative = useRelativePrices();
  const dispatch = useDispatch();
  return (
    <div>
      <h2>
        <Localize name="lang_primary_title" />
      </h2>
      <p>
        <Localize name="lang_primary_text" />
      </p>
      {prices.languages.map((lang) => (
        <Button
          key={lang.id}
          text={localize(lang.describe)}
          active={config.language === lang.id}
          onClick={() => dispatch(setLanguage(lang.id))}
        >
          {lang.price === 0 ? (
            <div>{localize("included")}</div>
          ) : (
            <div>
              {numberToCurrency(lang.price, true, config.currency.text)}
            </div>
          )}
        </Button>
      ))}

      <h2>
        <Localize name="lang_additional_title" />
      </h2>
      <h3>
        <Localize name="lang_quantity_title" />
      </h3>
      <p>
        <Localize name="lang_quantity_text" />
      </p>
      <Button
        text={`${config.additional_languages} × ${localize(
          "additional_language"
        )}`}
        active={false}
      >
        <NumberButtonModule
          dec={() => {
            dispatch(setAdditionalLanguages(config.additional_languages - 1));
          }}
          inc={() => {
            dispatch(setAdditionalLanguages(config.additional_languages + 1));
          }}
          min={config.additional_languages === 0}
          max={
            config.additional_languages ===
            prices.additional_languages.length - 1
          }
        />
      </Button>

      <h3>
        <Localize name="lang_type_title" />
      </h3>
      <p>
        <Localize name="lang_type_text" />
      </p>
      <Button
        text={`${config.additional_languages} × ${localize("subtitled")}`}
        disabled={config.additional_languages === 0}
        active={config.additional_languages > 0 && !config.additional_dub}
        onClick={() => dispatch(setAdditionalDub(false))}
      >
        {config.additional_languages === 0 ? (
          <div>N/A</div>
        ) : (
          <div>
            {numberToCurrency(
              relative.additional_language_sub +
                relative.additional_language_translation,
              true,
              config.currency.text
            )}
          </div>
        )}
      </Button>
      <Button
        text={`${config.additional_languages} × ${localize("dubbed")}`}
        disabled={config.additional_languages === 0}
        active={config.additional_languages > 0 && config.additional_dub}
        onClick={() => dispatch(setAdditionalDub(true))}
      >
        {config.additional_languages === 0 ? (
          <div>N/A</div>
        ) : (
          <div>
            {numberToCurrency(
              relative.additional_language_narrator +
                relative.additional_language_translation,
              true,
              config.currency.text
            )}
          </div>
        )}
      </Button>
      <h3>
        <Localize name="lang_translate_title" />
      </h3>
      <p>
        <Localize name="lang_translate_text" />
      </p>
      <Button
        text={localize("translate_us")}
        disabled={config.additional_languages === 0}
        active={
          config.additional_languages > 0 && config.additional_translation
        }
        onClick={() => dispatch(setAdditionalTranslation(true))}
      >
        {config.additional_languages === 0 ? (
          <div>N/A</div>
        ) : (
          <div>{localize("included")}</div>
        )}
      </Button>
      <Button
        text={localize("translate_them")}
        disabled={config.additional_languages === 0}
        active={
          config.additional_languages > 0 && !config.additional_translation
        }
        onClick={() => dispatch(setAdditionalTranslation(false))}
      >
        {config.additional_languages === 0 ? (
          <div>N/A</div>
        ) : (
          <div>
            {numberToCurrency(
              -relative.additional_language_translation,
              true,
              config.currency.text
            )}
          </div>
        )}
      </Button>
    </div>
  );
}
