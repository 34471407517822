import React from "react";
import { useHistory, useParams } from "react-router-dom";

import "./Language.css";

function Language() {
  const params = useParams<{ [key: string]: string }>();
  const history = useHistory();
  function change() {
    if (params.lang === "en") history.push(`/sv/${params.currency}/${params.step}`);
    if (params.lang === "sv") history.push(`/en/${params.currency}/${params.step}`);
  }
  return (
    <div className="language" onClick={change}>
      {params.lang}
    </div>
  );
}

export default Language;
