import { ConfigActions, Currency, ICurrency } from "./types";
import { ConfigActionTypes } from "./types";

export interface ConfigurationState {
  currency: ICurrency;
  videos: number;
  duration: number;
  elearning: boolean;
  new_style: boolean;
  copywriter: boolean;
  narrator: number;
  language: number;
  additional_languages: number;
  additional_translation: boolean;
  additional_dub: boolean;
  cutdown: boolean;
  export_illustrations: boolean;
  special_offer: boolean;
  ad_licence: boolean;
}

const initial: ConfigurationState = {
  currency: Currency.SEK,
  videos: 1,
  duration: 2,
  elearning: false,
  new_style: true,
  copywriter: true,
  narrator: 2,
  language: 0,
  additional_languages: 0,
  additional_translation: true,
  additional_dub: true,
  cutdown: false,
  export_illustrations: false,
  special_offer: false,
  ad_licence: false,
};

export const configurationReducer = (
  state = initial,
  action: ConfigActions
): ConfigurationState => {
  switch (action.type) {
    case ConfigActionTypes.SET_CURRENCY:
      return { ...state, currency: action.payload };
    case ConfigActionTypes.SET_AD_LICENCE:
      return { ...state, ad_licence: action.payload };
    case ConfigActionTypes.SET_SPECIAL_OFFER:
      return { ...state, special_offer: action.payload };
    case ConfigActionTypes.SET_EXPORT_ILLUSTRATIONS:
      return { ...state, export_illustrations: action.payload };
    case ConfigActionTypes.SET_CUTDOWN:
      return { ...state, cutdown: action.payload };
    case ConfigActionTypes.SET_ADDITIONAL_TRANSLATION:
      return { ...state, additional_translation: action.payload };
    case ConfigActionTypes.SET_ADDITIONAL_DUB:
      return { ...state, additional_dub: action.payload };
    case ConfigActionTypes.SET_ADDITIONAL_LANGUAGE:
      return { ...state, additional_languages: action.payload };
    case ConfigActionTypes.SET_LANGUAGE:
      return { ...state, language: action.payload };
    case ConfigActionTypes.SET_COPY:
      return { ...state, copywriter: action.payload };
    case ConfigActionTypes.SET_NEW_STYLE:
      return { ...state, new_style: action.payload };
    case ConfigActionTypes.SET_NARRATOR:
      return { ...state, narrator: action.payload };
    case ConfigActionTypes.SET_ELEARNING:
      return {
        ...state,
        elearning: !state.elearning,
      };
    case ConfigActionTypes.SET_DURATION:
      const duration = state.duration + action.payload;
      return {
        ...state,
        duration: duration > 0 && duration < 7 ? duration : state.duration,
      };
    case ConfigActionTypes.SET_VIDEOS:
      const videos = state.videos + action.payload;
      return {
        ...state,
        videos: videos > 0 && videos < 11 ? videos : state.videos,
      };
    default:
      return state;
  }
};
