import React from "react";
import { useLocalize } from "../../lib/localise";

interface Langs {
  [key: string]: {
    [key: string]: string;
  };
}

interface Props {
  name: string;
}

const Localize = ({ name }: Props) => {
  const text = useLocalize();
  return <>{text(name)}</>;
};

export default Localize;
