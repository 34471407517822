import { FormTypes } from "./actions";

const initial = {
  submitting: false,
  submitted: false,
  name: "",
  org: "",
  email: "",
  phone: "",
  msg: "",
};

export type FormState = typeof initial;

export const FormReducer = (
  state = initial,
  action: { type: string; payload: any }
): FormState => {
  switch (action.type) {
    case FormTypes.SUBMIT_END:
      return { ...state, submitting: false, submitted: action.payload };
    case FormTypes.SUBMIT_START:
      return { ...state, submitting: true };
    case FormTypes.SET_MSG:
      return { ...state, msg: action.payload };
    case FormTypes.SET_ORG:
      return { ...state, org: action.payload };
    case FormTypes.SET_PHONE:
      return { ...state, phone: action.payload };
    case FormTypes.SET_EMAIL:
      return { ...state, email: action.payload };
    case FormTypes.SET_NAME:
      return { ...state, name: action.payload };
    default:
      return state;
  }
};
