import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberToCurrency } from "../../lib/format";

import { IAppState } from "../../store";
import { PriceState } from "../../store/prices/reducer";
import { ConfigurationState as ConfigState } from "../../store/configuration/reducer";
import {
  setVideos,
  setDuration,
  toggleElearning,
} from "../../store/configuration/actions";

import Button from "./button";
import { useCosts, useRelativePrices } from "../../lib/calc";
import NumberButtonModule from "./button/NumberButtonModule";
import Localize from "../localize";
import { useLocalize } from "../../lib/localise";

export default function StartingPoint() {
  const config = useSelector<IAppState, ConfigState>((store) => store.config);
  const priceList = useSelector<IAppState, PriceState>((store) => store.prices);
  const prices = useRelativePrices();
  const costs = useCosts();
  const localize = useLocalize();
  const dispatch = useDispatch();
  const incVideos = () => dispatch(setVideos(1));
  const decVideos = () => dispatch(setVideos(-1));
  const incDuration = () => dispatch(setDuration(1));
  const decDuration = () => dispatch(setDuration(-1));
  const disToggleElearn = () => dispatch(toggleElearning());

  return (
    <>
      <h2>
        <Localize name="start_quantity_title" />
      </h2>
      <p>
        <Localize name="start_quantity_text" />
      </p>
      <Button text={localize("quantity_" + (config.videos - 1))} active={false}>
        <NumberButtonModule
          dec={decVideos}
          inc={incVideos}
          min={config.videos === 1}
          max={config.videos === priceList.volume_multipliers.length}
        />
      </Button>
      <h2>
        <Localize name="start_duration_title" />
      </h2>
      <p>
        <Localize name="start_duration_text" />
      </p>
      <Button
        text={localize("duration_" + (config.duration - 1))}
        active={false}
      >
        <NumberButtonModule
          dec={decDuration}
          inc={incDuration}
          min={config.duration === 1}
          max={config.duration === priceList.duration_multipliers.length}
        />
      </Button>
      <h2>
        <Localize name="start_production_type_title" />
      </h2>
      <p>
        <Localize name="start_production_type_text" />
      </p>
      <Button
        text={"Video"}
        active={!config.elearning}
        onClick={() => disToggleElearn()}
      >
        <div>
          {numberToCurrency(
            costs.production +
              prices.brief +
              prices.library +
              prices.narrations[2].price +
              prices.copwriter +
              costs.videos,
            false,
            config.currency.text
          )}
        </div>
      </Button>
      <Button
        text={"Video + " + localize("e-learning")}
        active={config.elearning}
        onClick={() => disToggleElearn()}
      >
        <div>
          {numberToCurrency(
            costs.production +
              prices.brief +
              prices.library +
              prices.narrations[2].price +
              prices.copwriter +
              costs.videos +
              prices.elearning,
            false,
            config.currency.text
          )}
        </div>
      </Button>
    </>
  );
}
