import { useSelector } from "react-redux";
import { ConfigurationState as ConfState } from "../store/configuration/reducer";
import { Currency } from "../store/configuration/types";

import { IAppState } from "../store/index";
import { PriceState } from "../store/prices/reducer";

function norm(num: number, by: number) {
  return Math.ceil(num / by) * by;
}

function calcPrices(
  prices: PriceState,
  config: ConfState,
  currency: typeof Currency.SEK
) {
  const { videos, duration } = config;
  // Setup Multipliers
  const volume_m = prices.volume_multipliers[videos - 1].multiplier;
  const duration_m = prices.duration_multipliers[duration - 1].multiplier;
  const videos_m = videos * volume_m * duration_m;

  const conv = currency.conversion;
  const round = currency.round;

  // Calc Prices
  const videosPrice = norm(
    prices.base * prices.new_scene * videos_m * prices.adme_multiplier * conv,
    round
  );
  const elearning = norm(prices.elearning * volume_m * videos * conv, round);
  const copy = norm(prices.copywriter * videos_m * conv, round);
  const narrations = prices.naration.map((narration) => ({
    ...narration,
    price: norm(narration.price * volume_m * videos * conv, round),
  }));
  const additional_language_narrator = norm(
    prices.additional_language_narration *
      config.additional_languages *
      volume_m *
      videos *
      conv,
    round
  );
  const additional_language_m =
    prices.additional_languages[config.additional_languages].multiplier;
  const additional_language_sub = norm(
    prices.additional_language_sub *
      config.additional_languages *
      videos_m *
      additional_language_m *
      conv,
    round
  );
  const additional_language_translation = norm(
    prices.additional_language_translation *
      config.additional_languages *
      videos_m *
      conv,
    round
  );
  const cutdown = norm(prices.cutdown * videos * volume_m * conv, round);
  const export_illustrations = norm(
    prices.export_illustrations * videos_m * conv,
    round
  );
  const special_offer = norm(prices.special_offer * conv, round);
  const production = norm(4000 * videos_m * conv, round);
  const ad_licence = norm(prices.ad_licence * videos * (config.additional_languages + 1) * conv, round);
  return {
    additional_language_sub,
    additional_language_narrator,
    additional_language_translation,
    library: prices.new_library * conv,
    videos: videosPrice,
    elearning,
    copwriter: copy,
    narrations,
    brief: prices.breif * conv,
    cutdown,
    export_illustrations,
    special_offer,
    production,
    ad_licence,
  };
}

export function useRelativePrices(currency?: typeof Currency.SEK) {
  const price = useSelector<IAppState, PriceState>((store) => store.prices);
  const config = useSelector<IAppState, ConfState>((store) => store.config);
  if (!currency) currency = config.currency;
  const prices = calcPrices(price, config, currency);

  return prices;
}

export function useCosts(currency?: typeof Currency.SEK) {
  const config = useSelector<IAppState, ConfState>((store) => store.config);
  if (!currency) currency = config.currency;
  const relative = useRelativePrices(currency);
  const costs = {
    ...relative,
    total: 0,
    narrator: 0,
    additional_language: 0,
    addons: 0,
  };
  costs.elearning = relative.elearning * +config.elearning;
  costs.narrator = relative.narrations[config.narrator].price;
  costs.copwriter = relative.copwriter * +config.copywriter;
  costs.library = relative.library * +config.new_style;
  costs.additional_language =
    relative.additional_language_narrator * +config.additional_dub +
    relative.additional_language_sub * +!config.additional_dub +
    relative.additional_language_translation * +config.additional_translation;
  costs.addons =
    relative.cutdown * +config.cutdown +
    relative.export_illustrations * +config.export_illustrations +
    relative.special_offer * +config.special_offer;
  costs.videos = relative.videos;
  costs.ad_licence = relative.ad_licence * +config.ad_licence;
  costs.total =
    costs.production +
    costs.brief +
    costs.library +
    costs.elearning +
    costs.narrator +
    costs.copwriter +
    costs.addons +
    costs.additional_language +
    costs.videos +
    costs.ad_licence;
  return costs;
}

export const useConvert = (price: number) => {
  const currency = useSelector<IAppState, typeof Currency.SEK>(
    (state) => state.config.currency
  );
  return price * currency.conversion;
};
