import axios from "axios";
import { IAppState } from "..";
import { numberToCurrency } from "../../lib/format";
import { localize } from "../../lib/localise";
import { setMessage } from "../message/actions";

export enum FormTypes {
  SET_NAME = "SET_NAME",
  SET_EMAIL = "SET_EMAIL",
  SET_PHONE = "SET_PHONE",
  SET_ORG = "SET_ORG",
  SET_MSG = "SET_MSG",
  SUBMIT_START = "SUBMIT_START",
  SUBMIT_END = "SUBMIT_END",
}

const makeAction = <P>(type: FormTypes) => (payload: P) => ({
  type,
  payload,
});

export const setName = makeAction<string>(FormTypes.SET_NAME);
export const setEmail = makeAction<string>(FormTypes.SET_EMAIL);
export const setOrg = makeAction<string>(FormTypes.SET_ORG);
export const setPhone = makeAction<string>(FormTypes.SET_PHONE);
export const setMsg = makeAction<string>(FormTypes.SET_MSG);
export const submitStart = makeAction<void>(FormTypes.SUBMIT_START);
export const submitEnd = makeAction<boolean>(FormTypes.SUBMIT_END);

export const submit = (
  price: any,
  relative: any,
  costs: any,
  config: any,
  lang: string
) => {
  if (process.env.NODE_ENV === "development")
    return prodSubmit(price, relative, costs, config, lang);
  return prodSubmit(price, relative, costs, config, lang);
};

function buildItems(
  price: any,
  relative: any,
  costs: any,
  config: any,
  lang: string
) {
  return [
    {
      describe: config.elearning
        ? localize("elearning_" + (config.duration - 1), lang) + config.videos
        : localize("video_" + (config.duration - 1), lang) + config.videos,
      cost: `${numberToCurrency(
        costs.production +
          relative.brief +
          relative.library +
          relative.narrations[2].price +
          relative.copwriter +
          relative.videos +
          costs.elearning,
        false,
        config.currency.text
      )}`,
    },
    {
      describe: localize("new_style", lang),
      cost: config.new_style
        ? localize("included", lang)
        : numberToCurrency(-relative.library, true, config.currency.text),
    },
    {
      describe: localize("copywriter", lang),
      cost: config.copywriter
        ? localize("included", lang)
        : numberToCurrency(-relative.copwriter, true, config.currency.text),
    },
    {
      describe: "Voiceover: " + localize("voiceover_" + config.narrator, lang),
      cost:
        config.narrator === 2
          ? localize("included", lang)
          : numberToCurrency(
              relative.narrations[config.narrator].price -
                relative.narrations[2].price,
              true,
              config.currency.text
            ),
    },
    {
      describe: localize("language", lang),
      cost:
        config.language === 0
          ? localize("swedish", lang)
          : localize("english", lang),
    },
    {
      describe: localize("dubbed", lang),
      cost: numberToCurrency(
        relative.additional_language_narrator +
          relative.additional_language_translation,
        true,
        config.currency.text
      ),
      hidden: !config.additional_languages || !config.additional_dub,
    },
    {
      describe: localize("subtitled", lang),
      cost: numberToCurrency(
        relative.additional_language_sub +
          relative.additional_language_translation,
        true,
        config.currency.text
      ),
      hidden: !config.additional_languages || config.additional_dub,
    },
    {
      describe: localize(
        "translate_" + (config.additional_translation ? "us" : "them"),
        lang
      ),
      cost: !config.additional_translation
        ? numberToCurrency(
            -costs.additional_language_translation,
            true,
            config.currency.text
          )
        : localize("included", lang),
      hidden: !config.additional_languages,
    },
    {
      describe: localize("ad", lang),
      cost: numberToCurrency(relative.ad_licence, true, config.currency.text),
      hidden: !config.ad_licence,
    },
    {
      describe: localize("cutdown", lang),
      cost: numberToCurrency(relative.cutdown, true, config.currency.text),
      hidden: !config.cutdown,
    },
    {
      describe: localize("export_illustrations", lang),
      cost: numberToCurrency(
        relative.export_illustrations,
        true,
        config.currency.text
      ),
      hidden: !config.export_illustrations,
    },
    {
      describe: localize("premium_special", lang),
      cost: numberToCurrency(
        relative.special_offer,
        true,
        config.currency.text
      ),
      hidden: !config.special_offer,
    },
    {
      describe: "Discount",
      cost: `- ${numberToCurrency((costs.total * 0.1), false, config.currency.text)}`,
    },
    {
      describe: "Total",
      cost: `= ${numberToCurrency(costs.total - (costs.total * 0.1), false, config.currency.text)}`,
    },
  ];
}

export const devSubmit = (
  price: any,
  relative: any,
  costs: any,
  config: any,
  lang: string
) => (dispatch: (f: any) => void, getState: () => IAppState) => {
  dispatch(submitStart());
  dispatch(setMessage(localize("request", lang), "info"));
  const state = getState();
  console.log("FORM: -------------");
  console.log(state.form);
  console.log("ITEMS: -------------");
  console.log(buildItems(price, relative, costs, config, lang));
  console.log("language: " + lang);
  setTimeout(() => {
    const r = Math.random();
    if (r < 0.5) {
      dispatch(submitEnd(true));
      dispatch(setMessage(localize("success", lang), "success"));
    } else {
      dispatch(submitEnd(false));
      dispatch(setMessage(localize("failed", lang), "danger"));
    }
  }, 300);
};

export const prodSubmit = (
  price: any,
  relative: any,
  costs: any,
  config: any,
  lang: string
) => (dispatch: (f: any) => void, getState: () => IAppState) => {
  const state = getState();
  dispatch(submitStart());
  dispatch(setMessage(localize("request", lang), "info"));
  axios({
    method: "POST",
    url:
      "https://us-central1-adme-price-configurator.cloudfunctions.net/submit_dev",
    data: {
      ...state.form,
      language: lang,
      items: buildItems(price, relative, costs, config, lang),
    },
  })
    .then(() => {
      dispatch(submitEnd(true));
      dispatch(setMessage(localize("success", lang), "success"));
    })
    .catch(() => {
      dispatch(submitEnd(false));
      dispatch(setMessage(localize("failed", lang), "danger"));
    });
};
