import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { numberToCurrency } from "../../lib/format";

import { IAppState } from "../../store";
import { ConfigurationState as ConfigState } from "../../store/configuration/reducer";

import Button from "./button";
import {
  setCopy,
  setNarrator,
  setNewStyle,
} from "../../store/configuration/actions";
import { useRelativePrices } from "../../lib/calc";
import Localize from "../localize";
import { useLocalize } from "../../lib/localise";

export default function DrawerInvolvement() {
  const localize = useLocalize();
  const config = useSelector<IAppState, ConfigState>((store) => store.config);
  const cost = useRelativePrices();
  const dispatch = useDispatch();
  const disSetNarrator = (id: number) => dispatch(setNarrator(id));
  const disSetNewStyle = (id: boolean) => dispatch(setNewStyle(id));
  const disSetCopy = (id: boolean) => dispatch(setCopy(id));
  return (
    <>
      <h2>
        <Localize name="inv_style_title" />
      </h2>
      <p>
        <Localize name="inv_style_text" />
      </p>
      <Button
        text={localize("new_style")}
        active={config.new_style}
        onClick={() => disSetNewStyle(true)}
      >
        <div>{localize("included")}</div>
      </Button>
      <Button
        text={localize("no_new_style")}
        active={!config.new_style}
        onClick={() => disSetNewStyle(false)}
      >
        <div>{numberToCurrency(-cost.library, true, config.currency.text)}</div>
      </Button>

      <h2>
        <Localize name="inv_script_title" />
      </h2>
      <p>
        <Localize name="inv_script_text" />
      </p>
      <Button
        text={localize("copywriter")}
        active={config.copywriter}
        onClick={() => disSetCopy(true)}
      >
        <div>{localize("included")}</div>
      </Button>
      <Button
        text={localize("no_copywriter")}
        active={!config.copywriter}
        onClick={() => disSetCopy(false)}
      >
        <div>
          {numberToCurrency(-cost.copwriter, true, config.currency.text)}
        </div>
      </Button>

      <h2>
        <Localize name="inv_voice_title" />
      </h2>
      <p>
        <Localize name="inv_voice_text" />
      </p>
      {cost.narrations.map((narration) => (
        <Button
          key={narration.id}
          text={localize("voiceover_" + narration.id)}
          active={config.narrator === narration.id}
          onClick={() => disSetNarrator(narration.id)}
        >
          <div>
            {narration.primary
              ? localize("included")
              : numberToCurrency(
                  narration.price -
                    (cost.narrations.find((s) => s.primary)?.price || 0),
                  true,
                  config.currency.text
                )}
          </div>
        </Button>
      ))}
    </>
  );
}
