export enum ConfigActionTypes {
  SET_VIDEOS = "CONFIG/SET_VIDEOS",
  SET_DURATION = "CONFIG/SET_DURATION",
  SET_ELEARNING = "CONFIG/SET_ELEARNING",
  SET_NARRATOR = "CONFIG/SET_NARRATOR",
  SET_NEW_STYLE = "CONFIG/SET_NEW_STYLE",
  SET_COPY = "CONFIG/SET_COPY",
  SET_LANGUAGE = "CONFIG/SET_LANGUAGE",
  SET_CUTDOWN = "CONFIG/SET_CUTDOWN",
  SET_EXPORT_ILLUSTRATIONS = "CONFIG/SET_EXPORT_ILLUSTRATIONS",
  SET_SPECIAL_OFFER = "CONFIG/SET_SPECIAL_OFFER",
  SET_ADDITIONAL_LANGUAGE = "CONFIG/SET_ADDITIONAL_LANGUAGE",
  SET_ADDITIONAL_DUB = "CONFIG/SET_ADDITIONAL_DUB",
  SET_ADDITIONAL_TRANSLATION = "CONFIG/SET_ADDITIONAL_TRANSLATION",
  SET_AD_LICENCE = "CONFIG/SET_AD_LICENCE",
  SET_CURRENCY = "CONFIG/SET_CURRENCY",
}

export interface ISetVideos {
  type: ConfigActionTypes.SET_VIDEOS;
  payload: number;
}

export interface ISetDuration {
  type: ConfigActionTypes.SET_DURATION;
  payload: number;
}

export interface ISetNarrator {
  type: ConfigActionTypes.SET_NARRATOR;
  payload: number;
}

export interface ISetNewStyle {
  type: ConfigActionTypes.SET_NEW_STYLE;
  payload: boolean;
}

export interface ISetElearning {
  type: ConfigActionTypes.SET_ELEARNING;
  payload: boolean;
}

export interface ISetCopy {
  type: ConfigActionTypes.SET_COPY;
  payload: boolean;
}

export interface ISetLanguage {
  type: ConfigActionTypes.SET_LANGUAGE;
  payload: number;
}

export interface ISetAdditionalLanguage {
  type: ConfigActionTypes.SET_ADDITIONAL_LANGUAGE;
  payload: number;
}

export interface ISetAdditionalDub {
  type: ConfigActionTypes.SET_ADDITIONAL_DUB;
  payload: boolean;
}

export interface ISetAdditionalTranslation {
  type: ConfigActionTypes.SET_ADDITIONAL_TRANSLATION;
  payload: boolean;
}

export interface ISetCutdown {
  type: ConfigActionTypes.SET_CUTDOWN;
  payload: boolean;
}

export interface ISetExportIllustrations {
  type: ConfigActionTypes.SET_EXPORT_ILLUSTRATIONS;
  payload: boolean;
}

export interface ISetSpecialOffer {
  type: ConfigActionTypes.SET_SPECIAL_OFFER;
  payload: boolean;
}

export interface ISetAdLicence {
  type: ConfigActionTypes.SET_AD_LICENCE;
  payload: boolean;
}

export const Currency: ICurrencies = {
  SEK: {
    text: "SEK",
    conversion: 1,
    round: 100,
    prefix: "",
  },
  EUR: {
    text: "EUR",
    conversion: 0.1,
    round: 10,
    prefix: "€",
  },
  USD: {
    text: "USD",
    conversion: 0.12,
    round: 10,
    prefix: "$",
  },
};

export interface ICurrency {
  text: string;
  conversion: number;
  round: number;
  prefix: string;
}

export interface ICurrencies {
  [key: string]: ICurrency;
}

export interface ISetCurrency {
  type: ConfigActionTypes.SET_CURRENCY;
  payload: typeof Currency.SEK;
}

export type ConfigActions =
  | ISetVideos
  | ISetDuration
  | ISetNarrator
  | ISetNewStyle
  | ISetElearning
  | ISetCopy
  | ISetLanguage
  | ISetAdditionalLanguage
  | ISetAdditionalDub
  | ISetAdditionalTranslation
  | ISetCutdown
  | ISetExportIllustrations
  | ISetSpecialOffer
  | ISetAdLicence
  | ISetCurrency;
