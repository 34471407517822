import React from "react";
import { Link, useParams } from "react-router-dom";
import classnames from "classnames";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import Language from "./Language";

import "./Header.css";
import Localize from "../localize";

const steps = ["start", "involvement", "language", "addons", "summary"]

export function Header() {
  const params = useParams<{ step: string }>();
  function getTranslate() {
    let offset = 160;
    if (window.innerWidth < 1080) offset = 50;
    if (window.innerWidth < 400) offset = 30;

    return offset * steps.indexOf(params.step);
  }

  function getWidth() {
    let width = 160;
    if (window.innerWidth < 780) width = 50;
    if (window.innerWidth < 400) width = 30;
    return width;
  }
  return (
    <div className="header">
      <div className="header_section">
        <a className="header_logo" href="https://adme.se">
          <Logo />
        </a>
      </div>
      <div className="header_section">
        {
          steps.map((step, index) => 
            <Link
              key={step}
              className={classnames("header_step", {
                "header_step--active": params.step === step,
              })}
              to={step}
            >
              {index + 1}<span>.&nbsp;<Localize name={step} /></span>
            </Link>
          )
        }
        <div
          className="header_step-light"
          style={{ transform: "translateX(" + getTranslate() + "px)", width: getWidth() + "px" }}
        ></div>
      </div>
      <div className="header_section">
        <Language />
      </div>
    </div>
  );
}
