import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/app";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { initializeApp } from "firebase/app"
import "firebase/analytics"
import { getAnalytics } from 'firebase/analytics';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-PLRKVW2'
}

TagManager.initialize(tagManagerArgs)

const app = initializeApp({
  apiKey: "AIzaSyDTUNuuKd30_asD-CS9wrXsuleePgF8R60",
  authDomain: "adme-price-configurator.firebaseapp.com",
  databaseURL: "https://adme-price-configurator.firebaseio.com",
  projectId: "adme-price-configurator",
  storageBucket: "adme-price-configurator.appspot.com",
  messagingSenderId: "982309247625",
  appId: "1:982309247625:web:1b3c146c617e6ab3ea0458",
  measurementId: "G-FPRFBN4196"
});

getAnalytics(app)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/:lang?/:currency?/:step?" component={App} />
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
