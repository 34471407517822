import { MessageTypes } from "./actions";

const inital = {
  display: false,
  waiting: false,
  latest: Date.now(),
  type: "danger",
  text: "Your request is being submitted...",
  queue: [],
};

export type MessageState = typeof inital;

export const MessageReducer = (state = inital, action: any): MessageState => {
  switch (action.type) {
    case MessageTypes.ADD_QUEUE:
      return {
        ...state,
        // @ts-ignore
        queue: [...state.queue, action.payload],
      };
    case MessageTypes.REMOVE_QUEUE:
      return {
        ...state,
        queue: state.queue.slice(1),
      };
    case MessageTypes.SET_MESSAGE:
      return {
        ...state,
        text: action.payload.text,
        type: action.payload.type,
        display: true,
        latest: Date.now(),
      };
    case MessageTypes.SET_DISPLAY:
      return { ...state, display: action.payload };
    default:
      return state;
  }
};
