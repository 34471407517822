import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import { useHistory, useParams } from "react-router-dom";

import Drawer from "../drawer";
import Footer from "../footer";
import Header from "../header";
import Main from "../main/Main";

import "./App.css";
import { Message } from "../message/Message";
import { Currency } from "../../store/configuration/types";
import { useDispatch } from "react-redux";
import { setCurrency } from "../../store/configuration/actions";

const languages = ["sv", "en"];

function isValidLanguage(lang: string) {
  return lang && languages.indexOf(lang) !== -1;
}

function isValidCurrency(currency:string) {
  return currency && Object.keys(Currency).indexOf(currency.toUpperCase()) !== -1;
}
const steps = ["start", "involvement", "language", "addons", "summary"];
function isValidStep(step: string) {
  return step && steps.indexOf(step) !== -1;
}

interface ValidUrlProps {
  lang: string;
  currency: string;
  step: string;
}

function isValidUrl(props: ValidUrlProps) {
  const lang = isValidLanguage(props.lang);
  const currency = isValidCurrency(props.currency);
  const step = isValidStep(props.step);
  return {
    all: lang && currency && step,
    lang,
    currency,
    step
  };
}

function getValidUrl(props: ValidUrlProps) {
  const result = isValidUrl(props);
  const newParams = {...props};
  if (result.all) return null;
  if (!result.lang) newParams.lang = "sv";
  if (!result.currency) newParams.currency = "sek";
  if (!result.step) newParams.step = "start";
  return `/${newParams.lang}/${newParams.currency}/${newParams.step}`;
}

export function App() {
  const params = useParams<{ lang: string, currency: string, step: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const redirectTo = getValidUrl(params);
  const appBodyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (appBodyRef.current) appBodyRef.current.scrollTo(0,0);
  }, [params.step]);
  useEffect(() => {
    if (params.currency) {
      dispatch(setCurrency(Currency[params.currency.toUpperCase()]))
    }
  }, [params.currency, dispatch])
  if(redirectTo) {
    history.push(redirectTo);
    return null;
  }
  return (
    <div className="app">
      <Header />
      <div  ref={appBodyRef} className="app_body-wrapper">
        <div className={classnames("app_body", {
          "app_body--summary": params.step === "summary"
        })}>
          <Drawer />
          <div className="app_main">
            <Main />
          </div>
        </div>
      </div>
      <Footer />
      <Message />
    </div>
  );
}
