import { useParams } from "react-router-dom";
import en from "../locale/eng.json";
import sv from "../locale/sv.json";

const LANGUAGES = { en, sv };

interface Languages {
  [key: string]: { [key: string]: string };
}

export function useLocalize(dict: Languages = LANGUAGES) {
  const { lang } = useParams<{ lang: string }>();
  return (key: string) => localize(key, lang, dict);
}

export function localize(
  text: string,
  lang: string,
  dict: Languages = LANGUAGES
) {
  if (!lang) lang = "en";
  return dict[lang][text];
}
