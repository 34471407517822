import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { numberToCurrency } from '../../lib/format';
import { useCosts } from '../../lib/calc';
import './Price.css';
import { Currency } from '../../store/configuration/types';
import { ReactComponent as AngleDown } from '../../assets/angle-down.svg'
import { useHistory, useParams } from 'react-router-dom';

interface Prices {
  [key: string]: any;
}

interface Params {
  lang: string;
  currency: string;
  step: string;
}

export const Price = () => {
  const history = useHistory();
  const params = useParams<Params>();
  const ref = useRef<HTMLDivElement>(null);
  const currency = Currency[params.currency.toUpperCase()];
  const prices: Prices = {
    SEK: useCosts(Currency.SEK),
    EUR: useCosts(Currency.EUR),
    USD: useCosts(Currency.USD),
  };
  const [show, setShow] = useState(false);
  useEffect(() => {
    function close(event: any) {
      if (!ref.current) return;
      if (!ref.current.contains(event.target)) {
        setShow(false);
      }
    }

    if (show) document.addEventListener('click', close);
    else document.removeEventListener('click', close);
    return () => document.removeEventListener('click', close)
  }, [show])
  const toggleShow = () => setShow(!show);
  return (
    <div id="price" className="price" onClick={toggleShow} ref={ref}>
      <div className="price_total">Total</div>
      {numberToCurrency(
        prices[currency.text].total,
        false,
        currency.text
      )}
      <span style={{fontSize: 10, opacity: 0.8}}> (ex VAT)</span>
      <div
        className={classnames('price_dropdown', {
          'price_dropdown--show': show,
        })}
      >
        {Object.keys(Currency).filter(key => key !== currency.text).map((key) => {
          return (
            <div
              className="price_dropdown-item"
              key={key}
              //@ts-ignore
              onClick={() => history.push(`/${params.lang}/${key.toLowerCase()}/${params.step}`)}
            >
              {numberToCurrency(
                prices[key].total,
                false,
                Currency[key].text
              )}
              <span style={{ fontSize: 10, opacity: 0.8 }}> (ex VAT)</span>
            </div>
          );
        })}
        <div
              className="price_dropdown-item"
            >
              {numberToCurrency(
                prices[currency.text].total,
                false,
                currency.text
              )}
              <span style={{ fontSize: 10, opacity: 0.8 }}> (ex VAT)</span>
            </div>
      </div>
      <div className="price_icon">
        <AngleDown/>
      </div>
    </div>
  );
};
